@media (max-width: 899px) {
  .medium-hidden {
    display: block;
  }
  .small-hidden {
    display: none;
  }
}

@media (min-width: 900px) {
  .medium-hidden {
    display: none;
  }
  .small-hidden {
    display: block;
  }
}

.logo-image {
  object-fit: contain;
  width: 5%;
  height: auto;
  padding-right: 0.5rem;
  cursor: pointer;
}
